/* 
------------------------------------------------------------------------
This file is automatically generated by copying the contents
of /home/circleci/project/apps/accelerator/src/config.ts

Do not modify this file directly as your changes will be overwritten. 
Instead make your changes directly in the source file and restart the dev server (pnpm start:local).

These config files are ignored in the .gitignore and will not be committed
------------------------------------------------------------------------ 
*/

import { Application, CRUDPermissionIds } from "../types";

export const basePath = "/accelerator";

export const applicationId = "2b6b82ef-0682-4029-8e4c-89d1d5ea9e1c";

export const permissions = {
  configureAlarms: "368d9e9a-1d08-4411-a3a9-85f293c4935a",
  configureApplicationSettings: "2f7ebe68-44cd-498d-8947-c9a701e8bb56",
  configureAssets: "21495cb7-9854-4a47-901c-8d29c38df944",
  configureTagMappings: "0f20234e-5812-420e-8fc4-8bc4fc32edf3",
  persona_ammoniaOperations: "03bf7284-0b32-4848-964e-4ee99eff63d9",
  persona_ammoniaProcess: "11ffb4f1-a674-4ff3-b2eb-43a1d1ff84ed",
  persona_firedEquipment: "c319e4e6-0ca6-40da-97f0-52bf88fb855b",
  persona_processControls: "2c259f6d-cf7f-45aa-a975-8ec00032313a",
  persona_rotatingEquipment: "888520ee-1ff9-47a1-9434-6e4bacef91e5",
  persona_waterTreatment: "3d9436a6-ffe0-4e41-bd00-d59fd40690ba",
  viewAlarms: "1796ecf8-ce5d-480f-974d-8df405a644ae",
  viewApplicationSettings: "b073c36c-4f85-45cc-8dfe-d5bb9a3c0fe5",
  viewAssets: "84deebc0-3afa-49fe-b438-0dfccf221d74",
  viewTagMappings: "64cd3e13-b366-42fd-9f6b-d982613f8d93",
  createUpdateNROCAccelerator: "3e96c832-71fc-419a-9a18-a6bc643550a1",
  readNROCAccelerator: "403ee06f-5481-4057-aaf9-a88cb0393088",
  deleteNROCAccelerator: "5c245e32-3d34-4138-9228-362846710688"
};

export const viewPermissions = [
  permissions.viewAlarms,
  permissions.viewApplicationSettings,
  permissions.viewAssets,
  permissions.viewTagMappings
];

export const configurePermissions = [
  permissions.configureAlarms,
  permissions.configureApplicationSettings,
  permissions.configureAssets,
  permissions.configureTagMappings,
  permissions.persona_ammoniaOperations,
  permissions.persona_ammoniaProcess,
  permissions.persona_firedEquipment,
  permissions.persona_processControls,
  permissions.persona_rotatingEquipment,
  permissions.persona_waterTreatment
];

/** CRUD permissions for `alarms` */
export const AlarmsCRUDPermissionIds = {
  create: permissions.configureAlarms,
  read: permissions.viewAlarms,
  update: permissions.configureAlarms,
  delete: permissions.configureAlarms
} as const satisfies CRUDPermissionIds;

/** CRUD permissions for application settings */
export const ApplicationSettingsCRUDPermissionIds = {
  create: permissions.configureApplicationSettings,
  read: permissions.viewApplicationSettings,
  update: permissions.configureApplicationSettings,
  delete: permissions.configureApplicationSettings
} as const satisfies CRUDPermissionIds;

/** CRUD permissions for assets */
export const AssetsCRUDPermissionIds = {
  create: permissions.configureAssets,
  read: permissions.viewAssets,
  update: permissions.configureAssets,
  delete: permissions.configureAssets
} as const satisfies CRUDPermissionIds;

/** CRUD permissions for tag mappings */
export const TagMappingsCRUDPermissionIds = {
  create: permissions.configureTagMappings,
  read: permissions.viewTagMappings,
  update: permissions.configureTagMappings,
  delete: permissions.configureTagMappings
} as const satisfies CRUDPermissionIds;

/** CRUD permissions for NROC Accelerator */
export const NROCAcceleratorCRUDPermissionIds = {
  create: permissions.createUpdateNROCAccelerator,
  read: permissions.readNROCAccelerator,
  update: permissions.createUpdateNROCAccelerator,
  delete: permissions.deleteNROCAccelerator
} as const satisfies CRUDPermissionIds;

export const AcceleratorCRUDDefinitions = [
  { label: "Alarms", crud: AlarmsCRUDPermissionIds },
  { label: "Application Settings", crud: ApplicationSettingsCRUDPermissionIds },
  { label: "Assets", crud: AssetsCRUDPermissionIds },
  { label: "NROC Accelerator", crud: NROCAcceleratorCRUDPermissionIds },
  { label: "Tag Mappings", crud: TagMappingsCRUDPermissionIds }
];

const config: Application = {
  applicationId,
  name: "@nutrien-operations/accelerator",
  displayName: "Accelerator",
  icon: "gauge",
  path: {
    value: basePath
  },
  order: 1,
  permissions,
  menu: {
    label: "Accelerator",
    path: basePath,
    icon: "gauge",
    permission: viewPermissions,
    children: [
      {
        label: "Overview",
        path: "/overview",
        permission: viewPermissions
      },
      {
        label: "Asset Templates",
        path: "/asset-templates",
        permission: viewPermissions
      },
      {
        label: "Asset Templates (Alarms)",
        path: "/asset-persona-templates",
        permission: viewPermissions
      },
      {
        label: "Asset Notes",
        path: "/asset-notes",
        permission: viewPermissions
      },
      {
        label: "Asset Property Notes",
        path: "/asset-property-notes",
        permission: viewPermissions
      },
      {
        label: "Nameplate Templates",
        path: "/asset-nameplate-templates",
        permission: viewPermissions
      },
      {
        label: "Steady State",
        path: "/steady-state",
        permission: viewPermissions
      },
      {
        label: "Tag Notifications",
        path: "/tag-notifications",
        icon: "bell-ring",
        permission: viewPermissions
      },
      {
        label: "Tag Weights",
        path: "/tag-weights",
        icon: "rss",
        permission: configurePermissions
      },
      {
        label: "Tag Mapping",
        path: "/tag-mapping",
        permission: viewPermissions
      },
      {
        label: "Settings",
        path: "/",
        icon: "settings",
        defaultCollapsed: true,
        permission: viewPermissions,
        children: [
          {
            label: "Alarm Modes",
            path: "/alarm-modes",
            permission: viewPermissions
          },
          {
            label: "Assets",
            path: "/assets",
            permission: viewPermissions
          },
          {
            label: "Asset Types",
            path: "/asset-types",
            permission: viewPermissions
          },
          {
            label: "Asset Sub Types",
            path: "/asset-sub-types",
            permission: viewPermissions
          },
          {
            label: "Asset Properties",
            path: "/asset-properties",
            permission: viewPermissions
          },
          {
            label: "Datasources",
            path: "/data-sources",
            permission: viewPermissions
          },
          {
            label: "Nameplate Labels",
            path: "/nameplate-labels",
            permission: viewPermissions
          },
          {
            label: "Plants",
            path: "/plants",
            permission: viewPermissions
          },
          {
            label: "Production Units",
            path: "/production-units",
            permission: viewPermissions
          },
          {
            label: "Sub Areas",
            path: "/sub-areas",
            permission: viewPermissions
          },
          {
            label: "Tags",
            path: "/tags",
            permission: viewPermissions
          }
        ]
      },
      {
        label: "Admin",
        path: "/admin",
        permission: viewPermissions
      }
    ]
  },
  swagger: {
    localdev: { url: "https://dev-accelerator-v2-swagger-ui.np.nutrien.com" },
    development: { url: "https://dev-accelerator-v2-swagger-ui.np.nutrien.com" },
    uat: {
      url: "https://uat-accelerator-v2-swagger-ui.np.nutrien.com"
    },
    production: {
      url: "https://prod-accelerator-v2-swagger-ui.np.nutrien.com"
    }
  }
};

export default config;
