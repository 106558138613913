/* 
------------------------------------------------------------------------
This file is automatically generated by copying the contents
of /home/circleci/project/apps/ui-navigation/src/config.ts

Do not modify this file directly as your changes will be overwritten. 
Instead make your changes directly in the source file and restart the dev server (pnpm start:local).

These config files are ignored in the .gitignore and will not be committed
------------------------------------------------------------------------ 
*/

import { Application } from "../types";

export const impersonateUserPermissionId = "3ea5c194-cfb0-4e36-af05-e9c620752a9b";

const UINavigation: Application = {
  name: "@nutrien-operations/ui-navigation"
};

export default UINavigation;
