/* 
------------------------------------------------------------------------
This file is automatically generated by copying the contents
of /home/circleci/project/apps/mine-maintenance-portal/src/config.ts

Do not modify this file directly as your changes will be overwritten. 
Instead make your changes directly in the source file and restart the dev server (pnpm start:local).

These config files are ignored in the .gitignore and will not be committed
------------------------------------------------------------------------ 
*/

import { Application } from "../types";

export const basePath = "/mine-maintenance-portal";

const permissions = {
  public: "i-am-a-guid",
  configureApplication: "i-am-a-guid",
  viewApplication: "i-am-a-guid"
};

const applicationConfig: Application = {
  applicationId: "00000-0000-000-0000-00000",
  name: "@nutrien-operations/mine-maintenance-portal",
  displayName: "Mine Maintenance Portal",
  icon: "calendar-check",
  path: {
    value: basePath
  },
  order: 1,
  permissions,
  menu: {
    label: "Mine Maintenance Portal",
    path: basePath,
    icon: "calendar-check",
    children: [
      {
        label: "Change Password",
        path: "/change-password",
        permission: permissions.public
      },
      {
        label: "Maintenance History",
        path: "/maintenance-history",
        permission: permissions.public
      },
      {
        label: "Shift",
        path: "/shift/shift-form",
        permission: permissions.public,
        defaultCollapsed: true,
        children: [
          {
            label: "Shift Form",
            path: "/shift/shift-form",
            permission: permissions.public
          },
          {
            label: "Review Shift Data",
            path: "/shift/review-shift-data",
            permission: permissions.public
          },
          {
            label: "View Forecast",
            path: "/shift/view-forecast",
            permission: permissions.public
          }
        ]
      },
      {
        label: "Planning",
        path: "/planning",
        permission: permissions.public,
        defaultCollapsed: true,
        children: [
          {
            label: "Manage Forecast",
            path: "/planning/manage-forecast",
            permission: permissions.public
          }
        ]
      },
      {
        label: "Checklist",
        path: "/checklist/checklist-entry",
        permission: permissions.public,
        defaultCollapsed: true,
        children: [
          {
            label: "Checklist Entry",
            path: "/checklist/checklist-entry",
            permission: permissions.public
          },
          {
            label: "Equipment Viewer",
            path: "/checklist/equipment-viewer",
            permission: permissions.public
          },
          {
            label: "Equipment History",
            path: "/checklist/equipment-history",
            permission: permissions.public
          }
        ]
      },
      {
        label: "Checklist Admin",
        path: "/checklist-admin/checklist-editor",
        permission: permissions.public,
        defaultCollapsed: true,
        children: [
          {
            label: "Checklist Editor",
            path: "/checklist-admin/checklist-editor",
            permission: permissions.public
          },
          {
            label: "New/Active Checklists",
            path: "/checklist-admin/new-active-checklists",
            permission: permissions.public
          },
          {
            label: "Equipment Editor",
            path: "/checklist-admin/equipment-editor",
            permission: permissions.public
          },
          {
            label: "Employees",
            path: "/checklist-admin/employees",
            permission: permissions.public
          },
          {
            label: "Crews",
            path: "/checklist-admin/crews",
            permission: permissions.public
          },
          {
            label: "Employee Groups",
            path: "/checklist-admin/employee-groups",
            permission: permissions.public
          }
        ]
      },
      {
        label: "Admin",
        path: "/admin/components",
        permission: permissions.public,
        defaultCollapsed: true,
        children: [
          {
            label: "Components",
            path: "/admin/components",
            permission: permissions.public
          },
          {
            label: "Crews",
            path: "/admin/crews",
            permission: permissions.public
          },
          {
            label: "Employee Groups",
            path: "/admin/employee-groups",
            permission: permissions.public
          },
          {
            label: "Employees",
            path: "/admin/employees",
            permission: permissions.public
          },
          {
            label: "Equipment",
            path: "/admin/equipment",
            permission: permissions.public
          },
          {
            label: "Equipment Types",
            path: "/admin/equipment-types",
            permission: permissions.public
          },
          {
            label: "Department Equipment",
            path: "/admin/department-equipment",
            permission: permissions.public
          },
          {
            label: "Jobs",
            path: "/admin/jobs",
            permission: permissions.public
          },
          {
            label: "Subcomponents",
            path: "/admin/subcomponents",
            permission: permissions.public
          },
          {
            label: "Delays",
            path: "/admin/delays",
            permission: permissions.public
          },
          {
            label: "Report Subscriptions",
            path: "/admin/report-subscriptions",
            permission: permissions.public
          }
        ]
      },
      {
        label: "User Admin",
        path: "/user-admin/roles",
        permission: permissions.public,
        defaultCollapsed: true,
        children: [
          {
            label: "Roles",
            path: "/user-admin/roles",
            permission: permissions.public
          },
          {
            label: "Users",
            path: "/user-admin/users",
            permission: permissions.public
          },
          {
            label: "User Roles",
            path: "/user-admin/user-roles",
            permission: permissions.public
          },
          {
            label: "Reset Passwords",
            path: "/user-admin/reset-passwords",
            permission: permissions.public
          },
          {
            label: "Departments",
            path: "/user-admin/departments",
            permission: permissions.public
          }
        ]
      },
      {
        label: "Daily Reports",
        path: "/daily-reports",
        permission: permissions.public
      }
    ]
  }
};

export default applicationConfig;
