/* 
------------------------------------------------------------------------
This file is automatically generated by copying the contents
of /home/circleci/project/apps/common-data-admin/src/config.ts

Do not modify this file directly as your changes will be overwritten. 
Instead make your changes directly in the source file and restart the dev server (pnpm start:local).

These config files are ignored in the .gitignore and will not be committed
------------------------------------------------------------------------ 
*/

import { Application } from "../types";

export const applicationId = "7072099e-d4bd-4429-9610-6775a5c80087";

export const basePath = "/common-data";

export const permissions = {
  create: "a8324764-9de5-4b3d-9037-1a3aac49bf94",
  updatePeopleData: "1c6dcc56-2571-4920-9948-d45c77c188d4",
  delete: "cad48ac7-3c4c-4d88-a270-0ee268239e0a",
  viewPeopleData: "6fed1479-ac57-4282-ab91-d18517f5d5d0",
  manageShifts: "eff947e6-a94e-4240-8a1e-b43a7d4b2014",
  userImpersonation: "3ea5c194-cfb0-4e36-af05-e9c620752a9b"
};

export const commonPermissions = [
  permissions.create,
  permissions.updatePeopleData,
  permissions.delete
];

const applicationConfig: Application = {
  applicationId,
  name: "@nutrien-operations/common-data-admin",
  displayName: "Common Data",
  icon: "database",
  path: {
    value: basePath
  },
  permissions,
  order: 1,
  menu: {
    label: "Common Data",
    path: "/",
    icon: "users-manage",
    children: [
      {
        label: "Applications",
        path: "/applications",
        permission: commonPermissions
      },
      {
        label: "Sites",
        path: "/sites",
        permission: commonPermissions
      },
      {
        label: "Shifts",
        path: "/shifts",
        children: [
          {
            label: "Shift Schedule",
            path: "/shifts/shift-schedules",
            permission: permissions.manageShifts
          },
          {
            label: "Shift Rotation",
            path: "/shifts/shift-rotations",
            permission: permissions.manageShifts
          }
        ],
        permission: permissions.manageShifts
      },
      {
        label: "People",
        path: "/users",
        permission: permissions.updatePeopleData
      },
      {
        label: "Admin",
        path: "/admin",
        permission: commonPermissions
      }
    ]
  },
  swagger: {
    localdev: { url: "https://dev-commondata-swagger-ui.np.nutrien.com" },
    development: { url: "https://dev-commondata-swagger-ui.np.nutrien.com" },
    uat: {
      url: "https://uat-commondata-swagger-ui.np.nutrien.com"
    },
    production: {
      url: "https://prod-commondata-swagger-ui.np.nutrien.com"
    }
  }
};

export default applicationConfig;
