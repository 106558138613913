import { applications } from "./applications";
import { ApplicationGroup } from "../types";

export const groupedApplications: Record<string, ApplicationGroup> = Object.freeze({
  "my-applications": {
    displayName: "My Applications",
    icon: "grid",
    applications: [
      applications["accelerator"],
      applications["calculation-engine"],
      applications["manual-entry"],
      applications["material-balance"],
      applications["mine-maintenance-portal"],
      applications["potash-reporting"],
      applications["safe-production-calendar"],
      applications["shift-logs"]
    ]
  },
  admin: {
    displayName: "Admin",
    icon: "grid",
    applications: [applications["common-data-admin"], applications["list-of-values-admin"]]
  }
});
