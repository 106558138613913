/* 
------------------------------------------------------------------------
This file is automatically generated by copying the contents
of /home/circleci/project/apps/material-balance/src/config.ts

Do not modify this file directly as your changes will be overwritten. 
Instead make your changes directly in the source file and restart the dev server (pnpm start:local).

These config files are ignored in the .gitignore and will not be committed
------------------------------------------------------------------------ 
*/

import { Application } from "../types";

export const applicationId = "4be661f8-400e-49cc-b7d9-cd2354e2e2ad";

export const permissions = {
  viewBalances: "624375d1-7995-4d01-8482-0c34d1cd1dcc",
  editBalances: "95089261-92ba-4f96-828d-c432ece6fcb0",
  configureBalances: "8d7f058d-860b-444f-a1e9-7e270e9558ef",
  viewProducts: "8024b1bc-6836-4aec-b5a8-878b9b14a9c3",
  configureApplication: "6deea8ab-d10a-4604-8907-9920b79d003f",
  manualReprocess: "4fba7738-c437-4527-a24f-a792b5abdc82",
  lockAndUnlockBalances: "fcc55405-0562-419f-8beb-3993a08c0354"
};

const materialBalance: Application = {
  applicationId,
  name: "@nutrien-operations/material-balance",
  displayName: "Material Balance",
  icon: "scale",
  path: {
    value: "/material-balance"
  },
  permissions,
  order: 1,
  menu: {
    label: "Material Balance",
    path: "/material-balance",
    icon: "scale",
    children: [
      {
        label: "Product Balances",
        path: "/product-balances",
        icon: "scale",
        permission: [permissions.viewBalances, permissions.editBalances]
      },
      {
        label: "Manual Reprocess",
        path: "/manual-reprocess",
        permission: permissions.manualReprocess,
        icon: "refresh"
      },
      {
        label: "Products",
        path: "/products",
        permission: permissions.viewProducts,
        icon: "list"
      },
      {
        label: "Manual Entry",
        path: "/sheets",
        permission: Object.values(permissions)
      },
      {
        icon: "layers",
        label: "Reports",
        path: "/reports",
        permission: permissions.viewBalances
      },
      {
        label: "Admin",
        path: "/admin",
        icon: "users-manage",
        permission: permissions.configureApplication
      },
      {
        label: "Site Settings",
        icon: "settings",
        path: "/settings",
        permission: permissions.configureApplication
      },
      {
        label: "Upload to ERP",
        icon: "upload",
        path: "/csv-templates",
        permission: [
          permissions.configureApplication,
          permissions.viewBalances,
          permissions.editBalances
        ]
      }
    ]
  },
  swagger: {
    localdev: { url: "https://dev-material-balance-swagger-ui.np.nutrien.com" },
    development: { url: "https://dev-material-balance-swagger-ui.np.nutrien.com" },
    uat: {
      url: "https://uat-material-balance-swagger-ui.np.nutrien.com"
    },
    production: {
      url: "https://prod-material-balance-swagger-ui.np.nutrien.com"
    }
  }
};

export default materialBalance;
