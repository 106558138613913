/* 
------------------------------------------------------------------------
This file is automatically generated by copying the contents
of /home/circleci/project/apps/shift-logs/src/config.ts

Do not modify this file directly as your changes will be overwritten. 
Instead make your changes directly in the source file and restart the dev server (pnpm start:local).

These config files are ignored in the .gitignore and will not be committed
------------------------------------------------------------------------ 
*/

import { Application } from "../types";

export const basePath = "/shift-logs";

export const applicationId = "43325ba6-5f69-4480-8032-4f464ee336fc";

const permissions = {
  configureApplication: "07dd95c2-fea0-4a6b-9291-b5d75ae54e6c",
  viewShiftLogs: "07dd95c2-fea0-4a6b-9291-b5d75ae54e6c",
  locationConfiguration: "d80ccef8-951c-457e-bb9b-83cbadf16cd0"
};

const applicationConfig: Application = {
  applicationId,
  name: "@nutrien-operations/shift-logs",
  displayName: "Shift Logs",
  icon: "file-text",
  permissions,
  path: {
    value: basePath
  },
  order: 1,
  menu: {
    label: "Shift Logs",
    path: "",
    icon: "file-text",
    children: [
      {
        label: "Shift Logs",
        path: "/logs",
        permission: permissions.viewShiftLogs
      },
      {
        label: "Location Configuration",
        path: "/configuration",
        permission: permissions.locationConfiguration
      },
      {
        label: "Templates",
        path: "/templates",
        permission: permissions.locationConfiguration
      },
      {
        label: "Admin",
        path: "/admin",
        icon: "users-manage",
        permission: permissions.configureApplication
      }
    ]
  },
  shifts: {
    showSelector: true
  },
  swagger: {
    localdev: { url: "https://dev-shiftlogs-swagger-ui.np.nutrien.com" },
    development: { url: "https://dev-shiftlogs-swagger-ui.np.nutrien.com" },
    uat: {
      url: "https://uat-shiftlogs-swagger-ui.np.nutrien.com"
    },
    production: {
      url: "https://prod-shiftlogs-swagger-ui.np.nutrien.com"
    }
  }
};

export default applicationConfig;
