/* 
------------------------------------------------------------------------
This file is automatically generated by copying the contents
of /home/circleci/project/apps/landing/src/config.ts

Do not modify this file directly as your changes will be overwritten. 
Instead make your changes directly in the source file and restart the dev server (pnpm start:local).

These config files are ignored in the .gitignore and will not be committed
------------------------------------------------------------------------ 
*/

import { Application } from "../types";

const landing: Application = {
  name: "@nutrien-operations/landing",
  path: {
    value: "/",
    exact: true
  }
};

export default landing;
