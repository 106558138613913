/* 
------------------------------------------------------------------------
This file is automatically generated by copying the contents
of /home/circleci/project/apps/manual-entry/src/config.ts

Do not modify this file directly as your changes will be overwritten. 
Instead make your changes directly in the source file and restart the dev server (pnpm start:local).

These config files are ignored in the .gitignore and will not be committed
------------------------------------------------------------------------ 
*/

import { Application } from "../types";

export const applicationId = "bb49e15c-0c5d-4edd-b11a-3020fe60ef70";

export const basePath = "/manual-entry";

const permissions = {
  deleteData: "dcf2f312-894f-43f6-9cfd-0ca452bca90f", // Delete Online Log Sheet Data
  viewData: "f063cd19-fd2f-4124-845f-8522a4f4779a", // View Online Log Sheet Data
  createAndEditData: "987487f0-e07f-4a5f-9f9c-0ca3cf448378", // Create and Edit Online Log Sheet Data
  configureApplication: "deb924d5-16f6-4c99-8c74-514aa67da9ea"
};

const applicationConfig: Application = {
  applicationId,
  name: "@nutrien-operations/manual-entry",
  displayName: "Manual Entry",
  icon: "hardDrive",
  path: {
    value: basePath
  },
  order: 1,
  permissions,
  menu: {
    label: "Manual Entry",
    path: "/",
    icon: "hardDrive",
    children: [
      {
        label: "Sheets",
        path: "/sheets",
        permission: permissions.viewData
      },
      {
        label: "Admin",
        path: "/admin",
        permission: Object.values(permissions)
      }
    ]
  },
  swagger: {
    localdev: { url: "https://dev-ols-swagger-ui.np.nutrien.com" },
    development: { url: "https://dev-ols-swagger-ui.np.nutrien.com" },
    uat: {
      url: "https://uat-ols-swagger-ui.np.nutrien.com"
    },
    production: {
      url: "https://prod-ols-swagger-ui.np.nutrien.com"
    }
  }
};

export default applicationConfig;
